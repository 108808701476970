<template>
  <div class="lottie-bg">
    <div id="lottie">
      <img src="../../static/image/live-logo.png" rel="preload" mode="widthFix" style="width: 100%;" @click="onLoadingLogin()"/>
    </div>
  </div>
</template>

<script>
  import { Icon, Notify } from "vant";
  import $store from "@/store";

  export default {
    components: {
      [Icon.name]: Icon,
      [Notify.name]: Notify,
    },
    data() {
      return {};
    },
    created() {
      if ($store.state.oauth.redirectUrl === null) {
        Notify({ type: 'primary', message: '请点击小鹿' });
      }
    },
    onShow() {
    },
    methods: {
      onLoadingLogin() {
        console.log('系统加载页面 $store.state.user.token:', $store.state.user.token)
        console.log('系统加载页面 $store.state.oauth.redirectUrl:', $store.state.oauth.redirectUrl)
        console.log('系统加载页面 $store.state.oauth.routerTo.fullPath:', $store.state.oauth.routerTo.fullPath)
        console.log('系统加载页面 跳转路径:', location.origin + '/#' + $store.state.oauth.routerTo.fullPath)
        this.$router.push({
          name: "index",
        });
      },
    }
  };
</script>

<style scoped lang="less">
  .lottie-bg {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  #lottie {
    width: 35%;
    display: block;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    margin: auto;
  }
</style>
